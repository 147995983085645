import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';

const Privacy = ({ data }) => (
  <Layout>
    <Head pageTitle={data.privacyJson.title} />
    <div style={{ textAlign: 'center' }}>
      <h2>{data.privacyJson.title}</h2>
    </div>
    <div style={{ marginLeft: '8vw', marginRight: '8vw' }}>
      <Box>
        <div
          dangerouslySetInnerHTML={{
            __html: data.privacyJson.content.childMarkdownRemark.html,
          }}
        />
      </Box>
    </div>
  </Layout>
);

Privacy.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Privacy;

export const query = graphql`
  query PrivacyQuery {
    privacyJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
